import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History, Location } from "history";

function ScrollToTop({ history }: { history: History }): JSX.Element {
  useEffect(() => {
    let oldLocationState: Location = history.location;
    const unlisten = history.listen((locationState) => {
      if (oldLocationState.pathname !== locationState.pathname) {
        window.scrollTo({ left: 0, top: 0 });
      }
      oldLocationState = locationState;
    });
    return () => {
      unlisten();
    };
  }, []);

  return <></>;
}

export default withRouter(ScrollToTop);
